import React, { useState } from "react";
import { Modal } from "react-responsive-modal";
import { Link } from "gatsby";
import FooterLogo from "../images/footer-logo.svg";
import GooglePlayBadge from "../images/google-play-badge.png";
import ISOBadge from "../images/ISOo.png";
import Facebook from "../images/s-link1.svg";
import Linkedin from "../images/s-link2.svg";
import Twitter from "../images/s-link3.svg";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import "../styles/footer.scss";
import {
  pageLink,
  downloadApp,
  blogUrl,
  careerUrl,
  fbLink,
  linkedinLink,
  twitterLink,
  contactUsLink,
} from "../constants/linkConstant";
import NodalSection from "../components/NodalSection";

import "react-responsive-modal/styles.css";
import KarmaNodal from "./KarmaNodal";

const Footer = () => {
  const [openModal, setOpenModal] = useState(false);
  const onOpenModal = () => setOpenModal(true);
  const onCloseModal = () => {
    setOpenModal(false);
  };

  const getYear = () => {
    return new Date().getFullYear();
  };
  return (
    <div className="footer-wrapper">
      {openModal && (
        <Modal
          open={openModal}
          onClose={onCloseModal}
          center
          classNames={{ modal: "grey-background" }}
          className="requestModal customModal"
        >
          <KarmaNodal onCloseModal={onCloseModal} />
        </Modal>
      )}
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-4" id="col-first">
              <div className="footer-logo">
                <img src={FooterLogo} alt="" className="img-fluid" />
                <div id="download-app">
                  {/* <a href={downloadApp} target="_blank">
                    <button>download app</button>
                  </a> */}
                  <p>
                    OnionLife Private Limited
                    <span> © {getYear()} </span>
                  </p>
                </div>

                <div id="iso-tag">
                  <img src={ISOBadge} alt="" />
                  <div className="iso-text">ISO 27001:2022</div>
                </div>
              </div>
            </div>
            <div className="divider" />
            <div className="col-2" id="col-second">
              <div className="company-list">
                <ul>
                  <li>
                    <Link href="#"> company</Link>
                  </li>
                  <li>
                    <Link href={pageLink.about}>About</Link>
                  </li>
                  <li>
                    <Link href={pageLink.ourSolutions}> Our Solutions</Link>
                  </li>
                  <li>
                    <Link href={pageLink.media}>Media</Link>
                  </li>
                  <li>
                    <Link target="_blank" href={blogUrl}>
                      Blog
                    </Link>
                  </li>
                  <li>
                    <Link to="/about#partners">Our Lending Partners</Link>
                  </li>
                  {/* <AnchorLink to="/about#partners">
                    <span>Our Lending Partners</span>
                  </AnchorLink> */}
                </ul>
              </div>
            </div>
            <div className="col-3" id="col-third">
              <div className="company-list">
                <ul>
                  <li>
                    <a href="#"> informations</a>
                  </li>
                  <li>
                    <Link href={pageLink.faq}>FAQ</Link>
                  </li>
                  <li>
                    <Link href={pageLink.privacyPolicy}> Privacy Policy</Link>
                  </li>
                  <li>
                    <Link href={pageLink.termsCondition}>
                      Terms & Conditions
                    </Link>
                  </li>
                  <li>
                    <Link href={"https://sachet.rbi.org.in"} target="_blank">
                      RBI Sachet
                    </Link>
                  </li>
                  <li>
                    <Link href={pageLink.grevianceRedressalPolicy}>
                      Grievances Redressal Policy
                    </Link>
                  </li>

                  <li>
                    <a href="#" onClick={onOpenModal}>
                      KarmaLife Nodal Officer
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-3" id="col-four">
              <div className="company-list">
                <ul>
                  <li>
                    {" "}
                    <a href="#"> get in touch</a>
                  </li>
                  <li>
                    <a
                      href={contactUsLink}
                      target="_blank"
                      style={{ textDecorationLine: "underline" }}
                    >
                      contact@karmalife.ai
                    </a>
                  </li>
                </ul>
                <div className="info2">
                  <p>
                    Urban vault 1497, Ground Floor, 19th Main Rd, Sector 1, HSR
                    Layout, Bengaluru, Karnataka 560102
                  </p>
                  {/* <a href={fbLink} target="_blank">
                    {" "}
                    <img src={Facebook} alt="" />{" "}
                  </a>
                  <a href={linkedinLink} target="_blank">
                    <img src={Linkedin} alt="" />{" "}
                  </a>
                  <a href={twitterLink} target="_blank">
                    {" "}
                    <img src={Twitter} alt="" />{" "}
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="footer-bg">
        <div className="divider-last" />
        <div className="container">
          <div className="row">
            <div className="footer-bottom">
              <div className="left-border" style={{ marginLeft: "-8px" }}>
                <a href={downloadApp} target="_blank">
                  <img
                    src={GooglePlayBadge}
                    alt=""
                    // style={{ height: "40px" }}
                  />
                </a>
              </div>

              <div className="right-border">
                <a href={fbLink} target="_blank">
                  <img src={Facebook} alt="" />{" "}
                </a>
                <a
                  href={linkedinLink}
                  target="_blank"
                  style={{ marginLeft: "17px" }}
                >
                  <img src={Linkedin} alt="" />{" "}
                </a>
                <a
                  href={twitterLink}
                  target="_blank"
                  style={{ marginLeft: "17px" }}
                >
                  <img src={Twitter} alt="" />{" "}
                </a>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
